import React from "react";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Icon } from "@ryerson/frontend.assets";
import { Typography } from "@ryerson/frontend.typography";
import styled from "@emotion/styled";
import { useApplication } from "@ryerson/frontend.application";
import { LoginContent } from "@components/Login/LoginContent";

export interface AccountProps {
	className?: string;
	isMobile: boolean;
}

const checkIcon = css`
	margin-right: 20px;
	align-self: center;
`;

const Divider = styled.hr`
	width: 100%;
	${(props: any) => {
		const { theme } = props;
		return css`
			color: ${theme.colors.primary.darkerGrey};
			opacity: 0.1;
		`;
	}}
`;

const AccountForm: React.FC<AccountProps> = ({ className = "", isMobile }) => {
	const { theme } = useTheme();
	const { white } = theme.colors.primary;

	const {
		localization: { language },
	} = useApplication();

	const content = LoginContent[language] || LoginContent.en;

	return (
		<Flex
			className={className}
			direction="column"
			justifyContent="space-evenly"
			css={{
				width: "100%",
				padding: isMobile ? "25px 0px 30px 0px" : "25px 35px 65px 35px",
			}}
		>
			<Typography size="lg" color={white}>
				{content.createAccountText}
			</Typography>
			{isMobile && <br />}
			<Divider {...{ theme }} />
			{isMobile && <br />}
			<FlexItem
				style={css`
					margin-bottom: ${isMobile ? "40px" : "56px"};
				`}
			>
				<Flex css={{ marginBottom: "12px" }}>
					<Icon icon="check" color={white} size="xs" css={checkIcon} />
					<Typography variant="div" color={white} css={{ marginTop: "0px" }}>
						{content.accessText}
					</Typography>
				</Flex>
				<Flex css={{ marginBottom: "12px" }}>
					<Icon icon="check" color={white} size="xs" css={checkIcon} />
					<Typography variant="div" color={white} css={{ marginTop: "0px" }}>
						{content.saveToShoppingList}
					</Typography>
				</Flex>

				<Flex css={{ marginBottom: "12px" }}>
					<Icon icon="check" color={white} size="xs" css={checkIcon} />
					<Typography variant="div" color={white} css={{ marginTop: "0px" }}>
						{content.quotesText}
					</Typography>
				</Flex>
				<Flex css={{ marginBottom: "12px" }}>
					<Icon icon="check" color={white} size="xs" css={checkIcon} />
					<Typography variant="div" color={white} css={{ marginTop: "0px" }}>
						{content.payInvoiceText}
					</Typography>
				</Flex>
				<Flex css={{ marginBottom: "12px" }}>
					<Icon icon="check" color={white} size="xs" css={checkIcon} />
					<Typography variant="div" color={white} css={{ marginTop: "0px" }}>
						{content.fasterCheckout}
					</Typography>
				</Flex>
				<Flex css={{ marginBottom: "12px" }}>
					<Icon icon="check" color={white} size="xs" css={checkIcon} />
					<Typography variant="div" color={white} css={{ marginTop: "0px" }}>
						{content.orderRenewalText}
					</Typography>
				</Flex>
			</FlexItem>
			{isMobile && <br />}
			<Divider {...{ theme }} />
			{isMobile && <br />}
			<FlexItem>
				<Typography color={white} css={{ marginTop: "0px" }} weight="bold" size="md">
					You are in a good company with Ryerson
				</Typography>
			</FlexItem>
			{isMobile && <br />}
			<FlexItem
				css={{
					height: "10px",
				}}
			>
				<Flex>
					<Typography
						variant={isMobile ? "h1" : "h2"}
						type="tertiary"
						css={{ marginTop: "0px" }}
					>
						70,000+
					</Typography>
					<Typography
						variant={isMobile ? "h1" : "h2"}
						type="tertiary"
						css={{
							marginTop: "0px",
							marginLeft: "36px",
						}}
					>
						180+
					</Typography>
				</Flex>
			</FlexItem>
			{isMobile && <br />}
			<FlexItem>
				<Flex>
					<Typography
						type="tertiary"
						css={{ marginTop: isMobile ? "20px" : "10px" }}
						size="md"
					>
						Metal
						<br />
						Products
					</Typography>
					<Typography
						type="tertiary"
						css={{
							marginTop: isMobile ? "20px" : "10px",
							marginLeft: isMobile ? "127px" : "119px",
						}}
						size="md"
					>
						Years
						<br />
						in Business
					</Typography>
				</Flex>
			</FlexItem>
		</Flex>
	);
};

export default AccountForm;
